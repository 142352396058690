/// <reference path="socketService.ts" />
/// <reference path="util.ts" />
/// <reference path="../app.ts" /> 
/// <reference path="../services/generic.services.ts" />




var app = angular.module('webApp', // : angular.IModule
    [
        'ngRoute', 'ipCookie', 'ngLocalize','dx',
        'monospaced.elastic', 'ngFileUpload', 'chart.js', 'checklist-model', 'multipleSelect',
        'ui.bootstrap', 'infinite-scroll','angular-cron-gen','toastr' ,'angular-cron-generator',
        'angularUtils.directives.dirPagination' , 'ui.bootstrap.pagination'
        
 
        //'builder', 'validator', 'validator.rules' , 'builder.components'
    ]);



// Disable Inspect Element
// document.addEventListener('contextmenu', function(e) {
//     e.preventDefault();
//   });
// document.onkeydown = (e) => {
//     if (e.key == 'F12') {
//         e.preventDefault();
//     }
//     if (e.ctrlKey && e.shiftKey && e.key == 'I') {
//         e.preventDefault();
//     }
//     if (e.ctrlKey && e.shiftKey && e.key == 'C') {
//         e.preventDefault();
//     }
//     if (e.ctrlKey && e.shiftKey && e.key == 'J') {
//         e.preventDefault();
//     }
//     if (e.ctrlKey && e.key == 'U') {
//         e.preventDefault();
//     }
// };

  
app.factory('socketServiceFactory', socketServiceFactory);
app.factory('socketService', socketServiceFactory);
app.directive('equal', [
    function() {
    
    var link = function($scope, $element, $attrs, ctrl) {
    
      var validate = function(viewValue) {
        var comparisonModel = $attrs.equal; 
    
        if(!viewValue || !comparisonModel){ 
          ctrl.$setValidity('equal', true);
        } 
        ctrl.$setValidity('equal', viewValue === comparisonModel );
        return viewValue;
      };
    
      ctrl.$parsers.unshift(validate);
      ctrl.$formatters.push(validate);
    
      $attrs.$observe('equal', function(comparisonModel){
            return validate(ctrl.$viewValue);
      });
    
    };
    
    return {
      require: 'ngModel',
      link: link
    };
    }]);


var logLoadtimes = false;


app.value('localeConf', {
    basePath: '/locales',
    defaultLocale: 'en-US',
    sharedDictionary: 'common',
    fileExtension: '.lang.json',
    persistSelection: true,
    cookieName: 'COOKIE_LOCALE_LANG',
    observableAttrs: new RegExp('^data-(?!ng-|i18n)'),
    delimiter: '::',
})
    .value('localeSupported', [
        'en-US',
        'en-ZA',
        'da-DK'
    ]);

class orderby {
    public field: string = "_created"
    public reverse: boolean = false;

    public constructor(init?: Partial<orderby>) {
        Object.assign(this, init);
    }
}

class configBlock {
    //static $inject = ['$builderProvider'];
    static $inject = [];
    constructor(
        //private $builderProvider
    ) {
    }
}
class runBlock {
    static $inject = ['$rootScope', '$location', 'ipCookie'];
    constructor(
        private $rootScope,
        private $location,
        private ipCookie
    ) {
        this.$rootScope.$on('$routeChangeStart', (...args) => { this.routeChangeStart.apply(this, args); });
    }
    routeChangeStart(event: any, next: any, current: any) {
        //var path = routeObj.$$route.originalPath;
        // var rootScope = this.$rootScope;
        // var location = this.$location;
        if (this.$rootScope.user == null) {
            if (next.templateUrl === "signin.html" ||
                next.templateUrl === "recoverpassword.html" ||
                next.templateUrl === "trust.html" ||
                next.templateUrl === "workflowinvoker.html") 
                {
            }
            else {
                var jwt = this.ipCookie('jwt');
                if (jwt == null) {
                    var url = this.$location.url();
                    this.ipCookie('refere', url, {});
                    this.$location.path("/signin");
                }
            }
        }
    }
}
app.run(runBlock);
app.config(configBlock);


class menuCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', 'locale'];
    jwt: string = '';
    user: socketuser |null;
    connecting: boolean;
    genericServices: GenericServiceClass;
    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public locale
    ) {
        this.connecting = true;
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket,this.socketService.namespace)

        this.$scope.$on('signin', (event, data) => {
            if (event && data) { }
            this.user = data;
            if (!$scope.$$phase) { $scope.$apply(); }
            this.setLocale('en-US');
        });
        this.$scope.$on('signout', (event, data) => {
            if (event && data) { }
            this.user= null;
            if (!$scope.$$phase) { $scope.$apply(); }
        });
        this.$scope.$on('ioconnected', (event, data) => {
            if (event && data) { }
            this.connecting = false;
            // $('.connecting').hide();
            // $('.notconnecting').show();
            if (!$scope.$$phase) { $scope.$apply(); }
        });
        this.$scope.$on('iodisconnected', (event, data) => {
            if (event && data) { }
            this.connecting = true;
            // $('.connecting').show();
            // $('.notconnecting').hide();
            if (!$scope.$$phase) { $scope.$apply(); }
        });
        // $('.connecting').show();
        // $('.notconnecting').hide();
        this.jwt = socketService.jwt();
    }

    setLocale(loc) {
        this.locale.setLocale(loc);
    };
    islocal(s) {
        if (s == this.locale.getLocale()) {
            return 'active';
        }
        return '';
    };
    ismenuselected(s, classname, classname2) {
        if(classname==null || classname==undefined) classname = 'active';
        if(classname2==null || classname2==undefined) classname2 = '';
        var path = this.$location.path();
        if (s == path) {
            return classname;
        }
        if(path.includes(s) && s != '/'){
            return classname;
        }
        return classname2;
    };
    hasrole(role) {
        if (!this.user) { return false; }
        if (!this.user.roles) { return false; }
        if (Array.isArray(role)) {
            var exists = this.user.roles.filter(r => role.indexOf(r.name) > -1);
            return (exists.length > 0);
        } else if (role == '') {
            return true
        } else {
            var exists = this.user.roles.filter(r => r.name == role);
            return (exists.length > 0);
        }
    }
}
app.controller('menuController', menuCtrl);



class baseEntities2Ctrl {
    order: orderby;
    filter:any=[];
    page: number = 1;
    expand:boolean=false;
    pagesize: number = globalPageSize;
    genericServices: GenericServiceClass;
    pageCount : number = 0 ;
    totalItems : number = 0 ;
    // currentPage : number = 1;
    loading: boolean = false;
    scrollloading: boolean = true;
    searchstring: string = '';
    showsubflows: boolean;
    entities: Array<any> = [];
    filesentities: Array<any> = [];
    projectsEntities: Array<any> = [];
    projectsJoin :Array<any> = [];
    inlineCount: number;
    deletecounter: number = 0;
    service: socketServiceClass; 
    workflowsService: WorkflowsServiceClass; 
    focuson: string = 'searchstring';
    public collection: string = '';
    public basequery: any = null;
   public basequeryProjects :any=null;
    public baseprojection: any = null;
    limit :number = globalLoadData;

    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory
    ) {
        this.order = new orderby({ field: "_created", reverse: true })
        this.service = socketService.namespace;
        this.genericServices = new GenericServiceClass(this.service.socket,this.service.namespace)

        this.workflowsService = new WorkflowsServiceClass(this.service.socket,this.service.namespace)
   //     this.workflowsService = new WorkflowsJobsServiceClass(this.service.socket,this.service.namespace);
    }
    orderby(field: string) {
        if (field == this.order.field) {
            this.order.reverse = !this.order.reverse;
        }
        else {
            this.order.field = field;
            this.order.reverse = false;
        }
        this.entities = [];
        this.page = 1;
        //this.loaddata();
        this.getPagingData();
    }
    async loaddata() {
        clearError(this.$scope);
        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        } else if (this.collection == 'users') {
            this.service = this.socketService.namespace;
        } else if (this.collection == 'files') {
            this.service = this.socketService.namespace;
        }
        if (!this.genericServices.isConnected) return;

        this.loading = true;
       // var t0 = performance.now();
        var query = this.basequery;
        var projectsQuery = this.basequeryProjects;
        if (this.searchstring !== '') {
            query.projectandname = {$regex: '/^' + this.searchstring + '$/i'};
        }

            
    try {
        
            //projects
            var projects = await this.genericServices.query(projectsQuery, this.baseprojection, this.limit, 0 , this.order.field, this.order.reverse, this.collection);
            this.loading = false;
            this.projectsEntities = this.projectsEntities.concat(projects);
   
           
            this.loading = false; 
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    //new function for getting projects and workflows
   

    async getPagingData() {
        clearError(this.$scope);
        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        } else if (this.collection == 'users') {
            this.service = this.socketService.namespace;
        }
        //debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
       // var t0 = performance.now();
        var query = this.basequery;
        if(this.searchstring == null || this.searchstring == '' ){
            if(query && query.projectandname) {
                delete query.projectandname;
             }
        }
        
        if (this.searchstring.trim() !== '') {
            if(!query) {query = {} }
            query.projectandname = {$regex : ".*"+this.searchstring +".*" , $options: "i"}
        }
        

        try {
            var Result :any = await this.genericServices.getPagingDetails(query, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection , true );
            var items = Result.items;
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);
            if(this.pageCount < this.page){ 
               this.page = this.pageCount;
           }
            this.loading = false; 
            this.entities = items; 
            this.scrollloading = (items.length > 0);
            this.loading = false; 
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    async loadProjectsAndWorkflows() {
        clearError(this.$scope);
        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        
        }
        if (!this.genericServices.isConnected) return;

        this.loading = true;
       // var t0 = performance.now();
        var query = "_type eq 'rpaworkflow'";
        var projectsQuery = "_type eq 'project'";
       
    try {
        
            //projects
            var projects = await this.genericServices.get(projectsQuery, this.limit, null , this.order.field, this.order.reverse, this.collection);
            this.loading = false;
            this.projectsEntities = this.projectsEntities.concat(projects);
            //workflows
            var workflows = await this.genericServices.get(query, this.limit, null , this.order.field, this.order.reverse, this.collection);
            this.loading = false;
            this.entities = this.entities.concat(workflows);
        
            this.projectsJoin =[];
            for(let projectItem of this.projectsEntities)
            {
                projectItem.WorkflowList=[];
                
                for(let workflowItem of this.entities )
                {
                    if(projectItem._id== workflowItem.projectid )
                    { 
                        projectItem.WorkflowList.push(workflowItem);
                    }
                }
                this.projectsJoin.push(projectItem);
            } 
            this.loading = false; 
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err); 
        }
    }
    GetData(pageNumber : number){
        this.page = pageNumber;
       // this.socketService.onSignedin(() => {
            this.getPagingData();            
      //  });
    }


 
    async delete(id) {
        if (!this.deletecounter) {
            this.deletecounter = 0;
        }
        this.loading = true;
        try {
          
           
            await this.genericServices.delete(id, this.collection);
            this.entities.forEach((entity, idx) => {
                if (entity._id == id) {
                    this.entities.splice(idx, 1);
                }
            })
            this.deletecounter++;
            this.loading = false;
        
            //if (this.deletecounter > 5 || this.entities.length == 0) {
            if (this.entities.length == 0) {
                this.page = 1;
                this.entities = [];
                this.deletecounter = 0;
                this.getPagingData();
            } else {
                this.loading = false;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            this.getPagingData();
        
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    async deleteProject(project) { 
        if (!this.deletecounter) {
            this.deletecounter = 0;
        }
        this.loading = true;
        try {
        
          
         var  res =  await this.workflowsService.deleteProjectsAndWorkflows(project); 
            console.log(res)
            this.projectsEntities.forEach((entity, idx) => {
                if (entity._id == project._id) {
                    this.projectsEntities.splice(idx, 1);
                }
            })
            this.deletecounter++;
            this.loading = false;
        
            //if (this.deletecounter > 5 || this.entities.length == 0) {
            if (this.projectsEntities.length == 0) {
                this.page = 1;
                this.projectsEntities = [];
                this.deletecounter = 0;
                this.getPagingData();
            } else {
                this.loading = false;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            this.getPagingData();
        
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    prettyDate(time) {
        try {
            var date = new Date(Date.parse(time));
            var diff = (((new Date()).getTime() - date.getTime()) / 1000);
            var day_diff = Math.floor(diff / 86400);
            if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) {
                 return this.toFormatedDate(time)  }
            return day_diff == 0 && (diff < 60 && Math.floor(diff) + " sec ago" || diff < 120 && "1 minute ago" || diff < 3600 && Math.floor(diff / 60) + " minutes ago" || diff < 7200 && "1 hour ago" || diff < 86400 && Math.floor(diff / 3600) + " hours ago") || day_diff == 1 && "Yesterday" || day_diff < 7 && day_diff + " days ago" || day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago";
        }
        catch (err) { 
            return time;
        }
    }
    toLocaleDateString(time) {
        return new Date(time).toLocaleDateString();
    }

    toFormatedDate(time){
        var myDate = new Date(time);
        return myDate.getDate()   + '/' + (myDate.getMonth()+1) + '/' + myDate.getFullYear();

    }

    search() {
        this.loading = true;
        this.entities = [];
        this.page = 1;
        this.getPagingData();
        //this.loaddata();
        this.loading = false;
    }
    more() {
        if (!this.genericServices.isConnected()) return;
        if (!this.scrollloading) { return; }
        if (this.loading == true) { return; }
        this.loading = true;
        this.page++; 
        this.loaddata();
    }
}

class baseEntitiesCtrl {
    order: orderby;
    page: number = 1;
    pagesize: number = globalPageSize;
    genericServices: GenericServiceClass;
    pageCount : number = 0;
    totalItems : number = 0 ;
    confirm:any;
    // currentPage : number = 1;
    loading: boolean = false;
    scrollloading: boolean = true;
    searchstring: string = '';
    showsubflows: boolean;
    entities: Array<any> = [];
    inlineCount: number;
    deletecounter: number = 0;
    service: socketServiceClass;
    workFlowsService: WorkflowsServiceClass;
    focuson: string = 'searchstring';
    todayDate : string ;
    public collection: string = '';
    public basequery: string = '';
    public baseprojection: string |null= null;

    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory
    ) {
        this.order = new orderby({ field: "_created", reverse: true })
        this.service = socketService.namespace;
        this.todayDate = TodayDate();
        this.genericServices = new GenericServiceClass(this.service.socket,this.service.namespace)

        this.workFlowsService = new WorkflowsServiceClass(this.service.socket,this.service.namespace)
    }

    preventWhiteSpaces(event){  
       
        if(event.originalEvent.which == 32){
            
            event.preventDefault(); 
        } 
    }
    orderby(field: string) {
        if (field == this.order.field) {
            this.order.reverse = !this.order.reverse;
        }
        else {
            this.order.field = field;
            this.order.reverse = false;
        }
        this.entities = [];
        this.page = 1;
        //this.loaddata();
        this.getPagingData();
    }
    async loaddata() {

        clearError(this.$scope);
        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        } else if (this.collection == 'users') {
            this.service = this.socketService.namespace;
        }
        //debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
        //var t0 = performance.now();
        var query = this.basequery;
        if (this.searchstring !== '') {
            if (query != '') { query += " and "; }
            query += "contains(name,'" + this.searchstring + "')";
        }
        try {
            var items = await this.genericServices.get(query, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection);
            this.loading = false; 
            this.entities = this.entities.concat(items); 
            this.scrollloading = (items.length > 0);
            this.loading = false; 
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }

    async getPagingData() {
        this.loading = true;
        clearError(this.$scope);
        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        } else if (this.collection == 'users') {
            this.service = this.socketService.namespace;
        }
        // debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
       // var t0 = performance.now();
        var query = this.basequery;
        if (this.searchstring !== '') {
            if (query != '') { query += " and "; }
            if(this.collection == 'assets'){
                query += "contains(AssetID,'" + this.searchstring + "')"
            }else if(this.collection == 'queues')
           {
            query += "contains(queuename,'" + this.searchstring + "')"
           }else{
            query += "contains(name,'" + this.searchstring + "')"
           }
            
        }
        try {

            // if user not admin then get owned items only  
            var user: any = this.socketService.user; 
            if (!user.roles.find(R => R._id == adminsRoleId)) {
                if(query){
                    query +=' and _createdbyid eq ' + "'" + user._id+ "'" ;
                }else{
                    query ='_createdbyid eq ' + "'" + user._id+ "'";
                }
                
            }            

            var Result :any = await this.genericServices.getPagingDetails(query, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection);
            var items = Result.items;
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);  
            this.loading = false; 
            this.entities = items; 
            this.scrollloading = (items.length > 0);
            this.loading = false; 
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }

    async getWorkflowsPagedData() {
        this.loading = true;
        clearError(this.$scope);
        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        } 
        // debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
       // var t0 = performance.now();
        var query = this.basequery;
        if (this.searchstring !== '') {
            if (query != '') { query += " and "; }
            query += "contains(name,'" + this.searchstring + "')"

        }
        try {

            // if user not admin then get owned items only  
            var user: any = this.socketService.user; 
            if (!user.roles.find(R => R._id == adminsRoleId)) {
                if(query){
                    query +=' and _createdbyid eq ' + "'" + user._id+ "'" ;
                }else{
                    query ='_createdbyid eq ' + "'" + user._id+ "'";
                }
                
            }            

            var Result :any = await this.genericServices.getPagingDetails(query, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection);
            var items = Result.items;
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);  
            this.loading = false; 
            this.entities = items; 
            this.scrollloading = (items.length > 0);
            this.loading = false; 
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    async getBackupsPagingData() {
        this.loading = true;
        clearError(this.$scope);
        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        } else if (this.collection == 'users') {
            this.service = this.socketService.namespace;
        }
        // debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
       // var t0 = performance.now();
        var query = this.basequery;
        if (this.searchstring !== '') {
            if (query != '') { query += " and "; }
            if(this.collection == 'assets'){
                query += "contains(AssetID,'" + this.searchstring + "')"
            }else if(this.collection == 'queues')
           {
            query += "contains(queuename,'" + this.searchstring + "')"
           }else{
            query += "contains(name,'" + this.searchstring + "')"
           }
            
        }
        try {


            var Result :any = await this.genericServices.getPagingDetails(query, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection);
            var items = Result.items;
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);  
            this.loading = false; 
            this.entities = items; 
            this.scrollloading = (items.length > 0);
            this.loading = false; 
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    
    GetData(pageNumber : number){
        this.page = pageNumber;
       // this.socketService.onSignedin(() => {
            this.getPagingData();            
      //  });
    }

    GetWorkflowsData(pageNumber : number){
        this.page = pageNumber;
       // this.socketService.onSignedin(() => {
            this.getWorkflowsPagedData();            
      //  });
    }
    GetBackupsData(pageNumber : number){
        this.page = pageNumber;
       // this.socketService.onSignedin(() => {
            this.getBackupsPagingData();            
      //  });
    }

    async delete(id) {
       
        if (!this.deletecounter) {
            this.deletecounter = 0;
        }
        this.loading = true;
        try {
           
           await this.genericServices.delete(id, this.collection);
            this.entities.forEach((entity, idx) => {
                if (entity._id == id) {
                    this.entities.splice(idx, 1);
                }
            })
            this.deletecounter++;
            // this.getPagingData();
            this.loading = false;
            //if (this.deletecounter > 5 || this.entities.length == 0) {
            if (this.entities.length == 0) {
                this.page = 1;
                this.entities = [];
                this.deletecounter = 0;
                this.getPagingData();
                // this.loaddata();
            } else {
                this.loading = false;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            this.getPagingData();
        
        } catch (err) {
            handleError(this.$scope, err);
        }
    
    }
    prettyDate(time) {
        try {
            var date = new Date(Date.parse(time));
            var diff = (((new Date()).getTime() - date.getTime()) / 1000);
            var day_diff = Math.floor(diff / 86400);
            if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) { return this.toFormatedDate(time)   }
            return day_diff == 0 && (diff < 60 && Math.floor(diff) + " sec ago" || diff < 120 && "1 minute ago" || diff < 3600 && Math.floor(diff / 60) + " minutes ago" || diff < 7200 && "1 hour ago" || diff < 86400 && Math.floor(diff / 3600) + " hours ago") || day_diff == 1 && "Yesterday" || day_diff < 7 && day_diff + " days ago" || day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago";
        }
        catch (err) { 
            return time;
        }
    }
    toLocaleDateString(time) {
        return new Date(time).toLocaleDateString();
    }

    toFormatedDate(time){
        var myDate = new Date(time);
        return myDate.getDate()   + '/' + (myDate.getMonth()+1) + '/' + myDate.getFullYear();

    }
    
    search() {
        this.loading = true;
        this.entities = [];
        this.page = 1;
        //this.loaddata();
        this.getPagingData();
        this.loading = false;
    }
    more() {
        if (!this.genericServices.isConnected()) return;
        if (!this.scrollloading) { return; }
        if (this.loading == true) { return; }
        this.loading = true;
        this.page++; 
        this.getPagingData();
    }
} 
class deleteEntityCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$routeParams', 'socketService','toastr'];
    loading: boolean = false;
    id: string;
    collection: string;
    service: socketServiceClass;
    genericServices: GenericServiceClass;
    model: any;
    toastrObj :any;
    constructor(
        public $scope,
        public $location,
        public $routeParams,
        public socketService: socketServiceFactory,
        public toastr
    ) {
        this.toastrObj = toastr;
        this.id = $routeParams.id;
        this.collection = $routeParams.collection;
        this.genericServices = new GenericServiceClass(this.service.socket,this.service.namespace)

        this.socketService.onSignedin(() => {
            this.loaddata();
        });
    }
    async loaddata() {
        try {
            this.service = this.socketService.namespace;
            if (this.collection == 'workflows') {
                this.service = this.socketService.namespace;
            }
            else if (this.collection == 'users') {
                this.service = this.socketService.namespace;
            }
            var query = "_id eq '" + this.id + "'";
            var items = await this.genericServices.get(query, 1, 0, null, false, this.collection);
            this.model = items[0];
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    async confirm() {
        clearError(this.$scope);
        this.loading = true;
        try {
            await this.genericServices.delete(this.id, this.collection);
            //$location.path("/main");
            if (this.collection == 'workflows') {
                if (this.model._type == 'instance') {
                    this.$location.path("/main");
                } else {
                    this.$location.path("/assets/" + this.collection);
                }
            }
            else if (this.collection == 'users') {
                this.$location.path("/assets/" + this.collection);
            } else {
                if (this.model._type == 'system') {
                    this.$location.path("/systems");
                } else {
                    this.$location.path("/assets/" + this.collection);
                }
            }
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
}



///<reference path="../node_modules/jsondiffpatch/dist/index.d.ts"/>

declare var jsondiffpatch: any;

class historyCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', '$routeParams', 'socketService'];
    public id: string = '';
    showsubflows: boolean;
    genericServices: GenericServiceClass;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public $routeParams,
        public socketService: socketServiceFactory
    ) {
        super($scope, $location, $compile, socketService);
        this.collection = this.$routeParams.collection;
        this.id = this.$routeParams.id;
        this.basequery = "(id eq '" + this.id + "')";
        this.genericServices = new GenericServiceClass(this.service.socket,this.service.namespace)

        this.socketService.onSignedin(async () => {
            
            await this.loaddata();

            //var left = { a: 3, b: 4 };
            //var right = { a: 5, c: 9 };
            var left = this.entities[0];
            var right = this.entities[1];
            var delta = jsondiffpatch.diff(left, right);
            // beautiful html diff
            document.getElementById('visual').innerHTML = jsondiffpatch.formatters.html.format(delta, left);

            // self-explained json
            //document.getElementById('annotated').innerHTML = jsondiffpatch.formatters.annotated.format(delta, left);

        });
    }
}
